import { GraphQLClient, RequestOptions } from "graphql-request";
import gql from "graphql-tag";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
export type MakeEmpty<
  T extends { [key: string]: unknown },
  K extends keyof T,
> = { [_ in K]?: never };
export type Incremental<T> =
  | T
  | {
      [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never;
    };
type GraphQLClientRequestHeaders = RequestOptions["requestHeaders"];
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  Time: { input: string; output: string };
};

export type ActivitiesDate = {
  __typename?: "ActivitiesDate";
  activities: Array<Activity>;
  acuteLoad: Scalars["Float"]["output"];
  date: Scalars["Time"]["output"];
  fatigue: Scalars["Float"]["output"];
  load: Scalars["Float"]["output"];
};

export type ActivitiesList = {
  __typename?: "ActivitiesList";
  dates: Array<ActivitiesDate>;
};

export type ActivitiesMutation = {
  __typename?: "ActivitiesMutation";
  create: Activity;
  delete: Scalars["Boolean"]["output"];
  update: Activity;
};

export type ActivitiesMutationCreateArgs = {
  input: CreateActivityInput;
};

export type ActivitiesMutationDeleteArgs = {
  id: Scalars["ID"]["input"];
};

export type ActivitiesMutationUpdateArgs = {
  input: UpdateActivityInput;
};

export type ActivitiesQuery = {
  __typename?: "ActivitiesQuery";
  count: Scalars["Int"]["output"];
  get: Activity;
  list: ActivitiesList;
};

export type ActivitiesQueryGetArgs = {
  id: Scalars["ID"]["input"];
};

export type ActivitiesQueryListArgs = {
  end: Scalars["Time"]["input"];
  start: Scalars["Time"]["input"];
};

export type Activity = {
  __typename?: "Activity";
  activeTimeSeconds?: Maybe<Scalars["Int"]["output"]>;
  activityType: ActivityType;
  avgPower?: Maybe<Scalars["Int"]["output"]>;
  avgSpeed?: Maybe<Scalars["Float"]["output"]>;
  bbox?: Maybe<Array<Scalars["Float"]["output"]>>;
  course?: Maybe<Course>;
  createdAt: Scalars["Time"]["output"];
  fit?: Maybe<FitFile>;
  id: Scalars["String"]["output"];
  intensity?: Maybe<Scalars["Float"]["output"]>;
  kilojoules?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  plannedAt?: Maybe<Scalars["Time"]["output"]>;
  plannedTimeSeconds?: Maybe<Scalars["Int"]["output"]>;
  plannedTrainingScore?: Maybe<Scalars["Int"]["output"]>;
  polyline?: Maybe<Scalars["String"]["output"]>;
  source?: Maybe<Source>;
  startedAt?: Maybe<Scalars["Time"]["output"]>;
  totalAscentMeters?: Maybe<Scalars["Int"]["output"]>;
  totalDistanceMeters?: Maybe<Scalars["Int"]["output"]>;
  totalTimeSeconds?: Maybe<Scalars["Int"]["output"]>;
  trainingScore?: Maybe<Scalars["Int"]["output"]>;
  weightedPower?: Maybe<Scalars["Int"]["output"]>;
};

export enum ActivityType {
  Generic = "GENERIC",
  Hike = "HIKE",
  IndoorRide = "INDOOR_RIDE",
  Ride = "RIDE",
  Run = "RUN",
  Ski = "SKI",
  Strength = "STRENGTH",
  Swim = "SWIM",
  Walk = "WALK",
  Yoga = "YOGA",
}

export type AdminMutation = {
  __typename?: "AdminMutation";
  impersonate: AuthToken;
  reprocessActivities: Scalars["Boolean"]["output"];
};

export type AdminMutationImpersonateArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type AdminMutationReprocessActivitiesArgs = {
  id: Scalars["String"]["input"];
};

export type AdminQuery = {
  __typename?: "AdminQuery";
  listUsers: Array<User>;
};

export type AuthToken = {
  __typename?: "AuthToken";
  accessToken: Scalars["String"]["output"];
  accessTokenExpiresAt: Scalars["Time"]["output"];
  refreshToken: Scalars["String"]["output"];
  refreshTokenExpiresAt: Scalars["Time"]["output"];
};

export type Course = {
  __typename?: "Course";
  altitude?: Maybe<Array<Scalars["Float"]["output"]>>;
  bbox: Array<Scalars["Float"]["output"]>;
  coords?: Maybe<Array<Maybe<Array<Scalars["Float"]["output"]>>>>;
  createdAt: Scalars["Time"]["output"];
  description?: Maybe<Scalars["String"]["output"]>;
  distance?: Maybe<Array<Scalars["Float"]["output"]>>;
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  polyline: Scalars["String"]["output"];
  totalAscentMeters?: Maybe<Scalars["Int"]["output"]>;
  totalDistanceMeters?: Maybe<Scalars["Int"]["output"]>;
  updatedAt: Scalars["Time"]["output"];
  weatherCells?: Maybe<Array<WeatherPoint>>;
};

export type CreateActivityInput = {
  plannedAt: Scalars["Time"]["input"];
  plannedTimeSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  plannedTrainingScore?: InputMaybe<Scalars["Int"]["input"]>;
  stravaRouteId?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<ActivityType>;
};

export type CreateMetricInput = {
  timestamp?: InputMaybe<Scalars["Time"]["input"]>;
  type: MetricType;
  value: Scalars["Float"]["input"];
};

export type Curve = {
  __typename?: "Curve";
  activities: Array<Maybe<Activity>>;
  heartRate: Array<Scalars["Int"]["output"]>;
  heartRateActivityIDs: Array<Scalars["String"]["output"]>;
  heartRateOffsets: Array<Scalars["Int"]["output"]>;
  power: Array<Scalars["Int"]["output"]>;
  powerActivityIDs: Array<Scalars["String"]["output"]>;
  powerOffsets: Array<Scalars["Int"]["output"]>;
  windows: Array<Scalars["Int"]["output"]>;
};

export type CurvesQuery = {
  __typename?: "CurvesQuery";
  get: Curve;
  range: Curve;
};

export type CurvesQueryGetArgs = {
  activityId: Scalars["ID"]["input"];
};

export type CurvesQueryRangeArgs = {
  end: Scalars["Time"]["input"];
  start: Scalars["Time"]["input"];
};

export type DashboardUser = {
  __typename?: "DashboardUser";
  activities: ActivitiesList;
  latestMetrics: Array<Maybe<Metric>>;
  user: User;
};

export type FitFile = {
  __typename?: "FitFile";
  altitude?: Maybe<Array<Scalars["Int"]["output"]>>;
  cadence?: Maybe<Array<Scalars["Int"]["output"]>>;
  cda?: Maybe<Array<Scalars["Float"]["output"]>>;
  cellUUID?: Maybe<Array<Scalars["String"]["output"]>>;
  coords?: Maybe<Array<Maybe<Array<Scalars["Float"]["output"]>>>>;
  distance?: Maybe<Array<Scalars["Int"]["output"]>>;
  effectiveWindSpeed?: Maybe<Array<Scalars["Float"]["output"]>>;
  grade?: Maybe<Array<Scalars["Float"]["output"]>>;
  heartRate?: Maybe<Array<Scalars["Int"]["output"]>>;
  laps: Array<Lap>;
  leftRightBalance?: Maybe<Array<Scalars["Int"]["output"]>>;
  power?: Maybe<Array<Scalars["Int"]["output"]>>;
  speed?: Maybe<Array<Scalars["Float"]["output"]>>;
  temp?: Maybe<Array<Scalars["Int"]["output"]>>;
  timestamps: Array<Scalars["Int"]["output"]>;
  weatherCells?: Maybe<Array<WeatherPoint>>;
  windAngleDegrees?: Maybe<Array<Scalars["Int"]["output"]>>;
};

export type GarminMutation = {
  __typename?: "GarminMutation";
  exchangeGarminToken: Scalars["Boolean"]["output"];
  getGarminAuthUrl: Scalars["String"]["output"];
};

export type GarminMutationExchangeGarminTokenArgs = {
  verifier: Scalars["String"]["input"];
};

export enum HrvRecoveryStatus {
  Great = "GREAT",
  Ok = "OK",
  Poor = "POOR",
}

export type HrvStatusDate = {
  __typename?: "HRVStatusDate";
  date: Scalars["Time"]["output"];
  metric?: Maybe<Metric>;
  percentile?: Maybe<Scalars["Float"]["output"]>;
  status?: Maybe<HrvRecoveryStatus>;
};

export type Lap = {
  __typename?: "Lap";
  end: Scalars["Time"]["output"];
  start: Scalars["Time"]["output"];
};

export type Metric = {
  __typename?: "Metric";
  activity?: Maybe<Activity>;
  id: Scalars["ID"]["output"];
  source: Source;
  timestamp: Scalars["Time"]["output"];
  type: MetricType;
  value: Scalars["Float"]["output"];
};

export enum MetricType {
  BodyFat = "BODY_FAT",
  Ftp = "FTP",
  Hrv = "HRV",
  MaxHeartRate = "MAX_HEART_RATE",
  RestingHeartRate = "RESTING_HEART_RATE",
  ThresholdHeartRate = "THRESHOLD_HEART_RATE",
  Wbal = "WBAL",
  Weight = "WEIGHT",
}

export type MetricsMutation = {
  __typename?: "MetricsMutation";
  create: Metric;
  delete: Scalars["Boolean"]["output"];
  estimateThresholdHistory: Scalars["Boolean"]["output"];
};

export type MetricsMutationCreateArgs = {
  input: CreateMetricInput;
};

export type MetricsMutationDeleteArgs = {
  id: Scalars["String"]["input"];
};

export type MetricsQuery = {
  __typename?: "MetricsQuery";
  list: Array<Metric>;
  listHRVStatus: Array<HrvStatusDate>;
};

export type MetricsQueryListArgs = {
  end: Scalars["Time"]["input"];
  start: Scalars["Time"]["input"];
  type?: InputMaybe<MetricType>;
};

export type MetricsQueryListHrvStatusArgs = {
  end: Scalars["Time"]["input"];
  start: Scalars["Time"]["input"];
};

export type Mutation = {
  __typename?: "Mutation";
  activities: ActivitiesMutation;
  admin: AdminMutation;
  garmin: GarminMutation;
  metrics: MetricsMutation;
  strava: StravaMutation;
  teams: TeamsMutation;
  upload: UploadMutation;
  users: UsersMutation;
  wahoo: WahooMutation;
  whoop: WhoopMutation;
};

export type Query = {
  __typename?: "Query";
  activities: ActivitiesQuery;
  admin: AdminQuery;
  curves: CurvesQuery;
  metrics: MetricsQuery;
  strava: StravaQuery;
  teams: TeamsQuery;
  users: UsersQuery;
};

export type SignupInput = {
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export enum Source {
  FtpEstimation = "FTP_ESTIMATION",
  Garmin = "GARMIN",
  Manual = "MANUAL",
  Strava = "STRAVA",
  Wahoo = "WAHOO",
  Whoop = "WHOOP",
}

export type StravaMutation = {
  __typename?: "StravaMutation";
  exchangeStravaToken: Scalars["Boolean"]["output"];
  getStravaAuthUrl: Scalars["String"]["output"];
};

export type StravaMutationExchangeStravaTokenArgs = {
  token: Scalars["String"]["input"];
};

export type StravaQuery = {
  __typename?: "StravaQuery";
  listRoutes: Array<StravaRoute>;
};

export type StravaQueryListRoutesArgs = {
  page: Scalars["Int"]["input"];
};

export type StravaRoute = {
  __typename?: "StravaRoute";
  createdAt: Scalars["Time"]["output"];
  description: Scalars["String"]["output"];
  distance: Scalars["Float"]["output"];
  elevationGain: Scalars["Float"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  polyline: Scalars["String"]["output"];
  private: Scalars["Boolean"]["output"];
};

export enum StravaRouteType {
  Ride = "Ride",
  Run = "Run",
}

export type Team = {
  __typename?: "Team";
  createdAt: Scalars["Time"]["output"];
  id: Scalars["String"]["output"];
  inviteCode: Scalars["String"]["output"];
  members: Array<TeamUser>;
  name: Scalars["String"]["output"];
  slug: Scalars["String"]["output"];
};

export type TeamDashboard = {
  __typename?: "TeamDashboard";
  members: Array<DashboardUser>;
  team: Team;
};

export enum TeamRole {
  Admin = "ADMIN",
  Member = "MEMBER",
}

export type TeamUser = {
  __typename?: "TeamUser";
  createdAt: Scalars["Time"]["output"];
  role: TeamRole;
  user: User;
};

export type TeamsMutation = {
  __typename?: "TeamsMutation";
  acceptInvite: Team;
  create: Team;
  switchUser: AuthToken;
  update: Team;
  updateRole: Scalars["Boolean"]["output"];
};

export type TeamsMutationAcceptInviteArgs = {
  inviteCode: Scalars["String"]["input"];
};

export type TeamsMutationCreateArgs = {
  name: Scalars["String"]["input"];
};

export type TeamsMutationSwitchUserArgs = {
  teamID: Scalars["String"]["input"];
  userID: Scalars["String"]["input"];
};

export type TeamsMutationUpdateArgs = {
  input: UpdateTeamInput;
};

export type TeamsMutationUpdateRoleArgs = {
  role: TeamRole;
  teamID: Scalars["String"]["input"];
  userID: Scalars["String"]["input"];
};

export type TeamsQuery = {
  __typename?: "TeamsQuery";
  dashboard: TeamDashboard;
  get: Team;
  getByInviteCode: Team;
  list: Array<Team>;
};

export type TeamsQueryDashboardArgs = {
  end: Scalars["Time"]["input"];
  start: Scalars["Time"]["input"];
  teamID: Scalars["String"]["input"];
};

export type TeamsQueryGetArgs = {
  id: Scalars["String"]["input"];
};

export type TeamsQueryGetByInviteCodeArgs = {
  inviteCode: Scalars["String"]["input"];
};

export type TeamsQueryListArgs = {
  role: TeamRole;
};

export type UpdateActivityInput = {
  ID: Scalars["String"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  plannedAt?: InputMaybe<Scalars["Time"]["input"]>;
  plannedTimeSeconds?: InputMaybe<Scalars["Int"]["input"]>;
  plannedTrainingScore?: InputMaybe<Scalars["Int"]["input"]>;
  type?: InputMaybe<ActivityType>;
};

export type UpdateTeamInput = {
  id: Scalars["String"]["input"];
  logoURL?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserInput = {
  avatarURL?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  thresholdEstimationEnabled?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadMutation = {
  __typename?: "UploadMutation";
  getURL: Scalars["String"]["output"];
};

export type User = {
  __typename?: "User";
  avatarURL?: Maybe<Scalars["String"]["output"]>;
  createdAt: Scalars["Time"]["output"];
  email?: Maybe<Scalars["String"]["output"]>;
  hasGarmin: Scalars["Boolean"]["output"];
  hasStrava: Scalars["Boolean"]["output"];
  hasWahoo: Scalars["Boolean"]["output"];
  hasWhoop: Scalars["Boolean"]["output"];
  id: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  subscriptionEndsAt: Scalars["Time"]["output"];
  thresholdEstimationEnabled: Scalars["Boolean"]["output"];
  trial: Scalars["Boolean"]["output"];
};

export type UserWithToken = {
  __typename?: "UserWithToken";
  token: AuthToken;
  user: User;
};

export type UsersMutation = {
  __typename?: "UsersMutation";
  addPushToken: Scalars["Boolean"]["output"];
  exchangeToken: AuthToken;
  getBillingPortalURL: Scalars["String"]["output"];
  login: UserWithToken;
  requestLoginCode: Scalars["Boolean"]["output"];
  signup: User;
  update: User;
};

export type UsersMutationAddPushTokenArgs = {
  input: Scalars["String"]["input"];
};

export type UsersMutationExchangeTokenArgs = {
  token: Scalars["String"]["input"];
};

export type UsersMutationLoginArgs = {
  code: Scalars["String"]["input"];
};

export type UsersMutationRequestLoginCodeArgs = {
  email: Scalars["String"]["input"];
};

export type UsersMutationSignupArgs = {
  input: SignupInput;
};

export type UsersMutationUpdateArgs = {
  input?: InputMaybe<UpdateUserInput>;
};

export type UsersQuery = {
  __typename?: "UsersQuery";
  get: User;
};

export type UsersQueryGetArgs = {
  id?: InputMaybe<Scalars["String"]["input"]>;
};

export type WahooMutation = {
  __typename?: "WahooMutation";
  exchangeWahooToken: Scalars["Boolean"]["output"];
  getWahooAuthUrl: Scalars["String"]["output"];
};

export type WahooMutationExchangeWahooTokenArgs = {
  token: Scalars["String"]["input"];
};

export type WeatherPoint = {
  __typename?: "WeatherPoint";
  cell: Scalars["String"]["output"];
  cloudCoveragePercent: Scalars["Int"]["output"];
  feelsLikeTemp: Scalars["Float"]["output"];
  humidity: Scalars["Int"]["output"];
  precipitationMm: Scalars["Float"]["output"];
  precipitationProbability: Scalars["Int"]["output"];
  pressure: Scalars["Int"]["output"];
  resolution: WeatherResolution;
  temp: Scalars["Float"]["output"];
  tempMax: Scalars["Float"]["output"];
  tempMin: Scalars["Float"]["output"];
  time: Scalars["Time"]["output"];
  uuid: Scalars["String"]["output"];
  weatherTypes: Array<WeatherType>;
  windDeg: Scalars["Int"]["output"];
  windGust: Scalars["Float"]["output"];
  windSpeed: Scalars["Float"]["output"];
};

export enum WeatherResolution {
  Day = "DAY",
  Hour = "HOUR",
  ThreeHour = "THREE_HOUR",
}

export enum WeatherType {
  BrokenClouds = "BROKEN_CLOUDS",
  ClearSky = "CLEAR_SKY",
  FewClouds = "FEW_CLOUDS",
  Mist = "MIST",
  Rain = "RAIN",
  ScatteredClouds = "SCATTERED_CLOUDS",
  ShowerRain = "SHOWER_RAIN",
  Snow = "SNOW",
  Thunderstorm = "THUNDERSTORM",
}

export type WhoopMutation = {
  __typename?: "WhoopMutation";
  exchangeWhoopToken: Scalars["Boolean"]["output"];
  getWhoopAuthUrl: Scalars["String"]["output"];
};

export type WhoopMutationExchangeWhoopTokenArgs = {
  token: Scalars["String"]["input"];
};

export type ListActivityDatesQueryVariables = Exact<{
  start: Scalars["Time"]["input"];
  end: Scalars["Time"]["input"];
}>;

export type ListActivityDatesQuery = {
  __typename?: "Query";
  activities: {
    __typename?: "ActivitiesQuery";
    list: {
      __typename?: "ActivitiesList";
      dates: Array<{
        __typename?: "ActivitiesDate";
        date: string;
        load: number;
        acuteLoad: number;
        fatigue: number;
        activities: Array<{
          __typename?: "Activity";
          id: string;
          name?: string | null;
          activityType: ActivityType;
          startedAt?: string | null;
          totalTimeSeconds?: number | null;
          activeTimeSeconds?: number | null;
          trainingScore?: number | null;
          weightedPower?: number | null;
          intensity?: number | null;
          kilojoules?: number | null;
          totalDistanceMeters?: number | null;
          totalAscentMeters?: number | null;
          avgPower?: number | null;
          avgSpeed?: number | null;
          source?: Source | null;
          createdAt: string;
          bbox?: Array<number> | null;
          polyline?: string | null;
          plannedAt?: string | null;
          plannedTimeSeconds?: number | null;
          plannedTrainingScore?: number | null;
          course?: {
            __typename?: "Course";
            id: string;
            name: string;
            totalDistanceMeters?: number | null;
            totalAscentMeters?: number | null;
            polyline: string;
          } | null;
        }>;
      }>;
    };
  };
};

export type ListActivitiesQueryVariables = Exact<{
  start: Scalars["Time"]["input"];
  end: Scalars["Time"]["input"];
}>;

export type ListActivitiesQuery = {
  __typename?: "Query";
  activities: {
    __typename?: "ActivitiesQuery";
    list: {
      __typename?: "ActivitiesList";
      dates: Array<{
        __typename?: "ActivitiesDate";
        date: string;
        activities: Array<{
          __typename?: "Activity";
          id: string;
          name?: string | null;
          activityType: ActivityType;
          startedAt?: string | null;
          totalTimeSeconds?: number | null;
          activeTimeSeconds?: number | null;
          trainingScore?: number | null;
          weightedPower?: number | null;
          intensity?: number | null;
          kilojoules?: number | null;
          totalDistanceMeters?: number | null;
          totalAscentMeters?: number | null;
          avgPower?: number | null;
          avgSpeed?: number | null;
          source?: Source | null;
          createdAt: string;
          bbox?: Array<number> | null;
          polyline?: string | null;
          plannedAt?: string | null;
          plannedTimeSeconds?: number | null;
          plannedTrainingScore?: number | null;
          course?: {
            __typename?: "Course";
            id: string;
            name: string;
            totalDistanceMeters?: number | null;
            totalAscentMeters?: number | null;
            polyline: string;
          } | null;
        }>;
      }>;
    };
  };
};

export type DisplayableActivitiesDateFragment = {
  __typename?: "ActivitiesDate";
  date: string;
  load: number;
  acuteLoad: number;
  fatigue: number;
  activities: Array<{
    __typename?: "Activity";
    id: string;
    name?: string | null;
    activityType: ActivityType;
    startedAt?: string | null;
    totalTimeSeconds?: number | null;
    activeTimeSeconds?: number | null;
    trainingScore?: number | null;
    weightedPower?: number | null;
    intensity?: number | null;
    kilojoules?: number | null;
    totalDistanceMeters?: number | null;
    totalAscentMeters?: number | null;
    avgPower?: number | null;
    avgSpeed?: number | null;
    source?: Source | null;
    createdAt: string;
    bbox?: Array<number> | null;
    polyline?: string | null;
    plannedAt?: string | null;
    plannedTimeSeconds?: number | null;
    plannedTrainingScore?: number | null;
    course?: {
      __typename?: "Course";
      id: string;
      name: string;
      totalDistanceMeters?: number | null;
      totalAscentMeters?: number | null;
      polyline: string;
    } | null;
  }>;
};

export type GetActivityQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetActivityQuery = {
  __typename?: "Query";
  activities: {
    __typename?: "ActivitiesQuery";
    get: {
      __typename?: "Activity";
      id: string;
      name?: string | null;
      activityType: ActivityType;
      startedAt?: string | null;
      totalTimeSeconds?: number | null;
      activeTimeSeconds?: number | null;
      trainingScore?: number | null;
      weightedPower?: number | null;
      intensity?: number | null;
      kilojoules?: number | null;
      totalDistanceMeters?: number | null;
      totalAscentMeters?: number | null;
      avgPower?: number | null;
      avgSpeed?: number | null;
      source?: Source | null;
      createdAt: string;
      bbox?: Array<number> | null;
      polyline?: string | null;
      plannedAt?: string | null;
      plannedTimeSeconds?: number | null;
      plannedTrainingScore?: number | null;
      fit?: {
        __typename?: "FitFile";
        timestamps: Array<number>;
        distance?: Array<number> | null;
        coords?: Array<Array<number> | null> | null;
        power?: Array<number> | null;
        cadence?: Array<number> | null;
        speed?: Array<number> | null;
        heartRate?: Array<number> | null;
        temp?: Array<number> | null;
        altitude?: Array<number> | null;
        leftRightBalance?: Array<number> | null;
        cellUUID?: Array<string> | null;
        effectiveWindSpeed?: Array<number> | null;
        cda?: Array<number> | null;
        weatherCells?: Array<{
          __typename?: "WeatherPoint";
          uuid: string;
          cell: string;
          time: string;
          resolution: WeatherResolution;
          temp: number;
          feelsLikeTemp: number;
          pressure: number;
          humidity: number;
          windSpeed: number;
          windDeg: number;
          windGust: number;
          cloudCoveragePercent: number;
          precipitationMm: number;
          precipitationProbability: number;
        }> | null;
        laps: Array<{ __typename?: "Lap"; start: string; end: string }>;
      } | null;
      course?: {
        __typename?: "Course";
        id: string;
        name: string;
        totalDistanceMeters?: number | null;
        totalAscentMeters?: number | null;
        polyline: string;
        description?: string | null;
        bbox: Array<number>;
        coords?: Array<Array<number> | null> | null;
        altitude?: Array<number> | null;
        distance?: Array<number> | null;
        weatherCells?: Array<{
          __typename?: "WeatherPoint";
          uuid: string;
          cell: string;
          time: string;
          resolution: WeatherResolution;
          temp: number;
          feelsLikeTemp: number;
          pressure: number;
          humidity: number;
          windSpeed: number;
          windDeg: number;
          windGust: number;
          cloudCoveragePercent: number;
          precipitationMm: number;
          precipitationProbability: number;
        }> | null;
      } | null;
    };
  };
};

export type GetActivityCurveQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetActivityCurveQuery = {
  __typename?: "Query";
  curves: {
    __typename?: "CurvesQuery";
    get: {
      __typename?: "Curve";
      power: Array<number>;
      powerOffsets: Array<number>;
      heartRate: Array<number>;
      heartRateOffsets: Array<number>;
      windows: Array<number>;
    };
  };
};

export type CreateActivityMutationVariables = Exact<{
  input: CreateActivityInput;
}>;

export type CreateActivityMutation = {
  __typename?: "Mutation";
  activities: {
    __typename?: "ActivitiesMutation";
    create: {
      __typename?: "Activity";
      id: string;
      name?: string | null;
      activityType: ActivityType;
      startedAt?: string | null;
      totalTimeSeconds?: number | null;
      activeTimeSeconds?: number | null;
      trainingScore?: number | null;
      weightedPower?: number | null;
      intensity?: number | null;
      kilojoules?: number | null;
      totalDistanceMeters?: number | null;
      totalAscentMeters?: number | null;
      avgPower?: number | null;
      avgSpeed?: number | null;
      source?: Source | null;
      createdAt: string;
      bbox?: Array<number> | null;
      polyline?: string | null;
      plannedAt?: string | null;
      plannedTimeSeconds?: number | null;
      plannedTrainingScore?: number | null;
      course?: {
        __typename?: "Course";
        id: string;
        name: string;
        totalDistanceMeters?: number | null;
        totalAscentMeters?: number | null;
        polyline: string;
      } | null;
    };
  };
};

export type UpdateActivityMutationVariables = Exact<{
  input: UpdateActivityInput;
}>;

export type UpdateActivityMutation = {
  __typename?: "Mutation";
  activities: {
    __typename?: "ActivitiesMutation";
    update: {
      __typename?: "Activity";
      id: string;
      name?: string | null;
      activityType: ActivityType;
      startedAt?: string | null;
      totalTimeSeconds?: number | null;
      activeTimeSeconds?: number | null;
      trainingScore?: number | null;
      weightedPower?: number | null;
      intensity?: number | null;
      kilojoules?: number | null;
      totalDistanceMeters?: number | null;
      totalAscentMeters?: number | null;
      avgPower?: number | null;
      avgSpeed?: number | null;
      source?: Source | null;
      createdAt: string;
      bbox?: Array<number> | null;
      polyline?: string | null;
      plannedAt?: string | null;
      plannedTimeSeconds?: number | null;
      plannedTrainingScore?: number | null;
      course?: {
        __typename?: "Course";
        id: string;
        name: string;
        totalDistanceMeters?: number | null;
        totalAscentMeters?: number | null;
        polyline: string;
      } | null;
    };
  };
};

export type DeleteActivityMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteActivityMutation = {
  __typename?: "Mutation";
  activities: { __typename?: "ActivitiesMutation"; delete: boolean };
};

export type DisplayableActivityFitFragment = {
  __typename?: "FitFile";
  timestamps: Array<number>;
  distance?: Array<number> | null;
  coords?: Array<Array<number> | null> | null;
  power?: Array<number> | null;
  cadence?: Array<number> | null;
  speed?: Array<number> | null;
  heartRate?: Array<number> | null;
  temp?: Array<number> | null;
  altitude?: Array<number> | null;
  leftRightBalance?: Array<number> | null;
  cellUUID?: Array<string> | null;
  effectiveWindSpeed?: Array<number> | null;
  cda?: Array<number> | null;
  weatherCells?: Array<{
    __typename?: "WeatherPoint";
    uuid: string;
    cell: string;
    time: string;
    resolution: WeatherResolution;
    temp: number;
    feelsLikeTemp: number;
    pressure: number;
    humidity: number;
    windSpeed: number;
    windDeg: number;
    windGust: number;
    cloudCoveragePercent: number;
    precipitationMm: number;
    precipitationProbability: number;
  }> | null;
  laps: Array<{ __typename?: "Lap"; start: string; end: string }>;
};

export type DisplayableWeatherCellFragment = {
  __typename?: "WeatherPoint";
  uuid: string;
  cell: string;
  time: string;
  resolution: WeatherResolution;
  temp: number;
  feelsLikeTemp: number;
  pressure: number;
  humidity: number;
  windSpeed: number;
  windDeg: number;
  windGust: number;
  cloudCoveragePercent: number;
  precipitationMm: number;
  precipitationProbability: number;
};

export type DisplayableActivityFragment = {
  __typename?: "Activity";
  id: string;
  name?: string | null;
  activityType: ActivityType;
  startedAt?: string | null;
  totalTimeSeconds?: number | null;
  activeTimeSeconds?: number | null;
  trainingScore?: number | null;
  weightedPower?: number | null;
  intensity?: number | null;
  kilojoules?: number | null;
  totalDistanceMeters?: number | null;
  totalAscentMeters?: number | null;
  avgPower?: number | null;
  avgSpeed?: number | null;
  source?: Source | null;
  createdAt: string;
  bbox?: Array<number> | null;
  polyline?: string | null;
  plannedAt?: string | null;
  plannedTimeSeconds?: number | null;
  plannedTrainingScore?: number | null;
  course?: {
    __typename?: "Course";
    id: string;
    name: string;
    totalDistanceMeters?: number | null;
    totalAscentMeters?: number | null;
    polyline: string;
  } | null;
};

export type DisplayableActivityCourseFragment = {
  __typename?: "Course";
  id: string;
  name: string;
  description?: string | null;
  totalDistanceMeters?: number | null;
  totalAscentMeters?: number | null;
  polyline: string;
  bbox: Array<number>;
  coords?: Array<Array<number> | null> | null;
  altitude?: Array<number> | null;
  distance?: Array<number> | null;
  weatherCells?: Array<{
    __typename?: "WeatherPoint";
    uuid: string;
    cell: string;
    time: string;
    resolution: WeatherResolution;
    temp: number;
    feelsLikeTemp: number;
    pressure: number;
    humidity: number;
    windSpeed: number;
    windDeg: number;
    windGust: number;
    cloudCoveragePercent: number;
    precipitationMm: number;
    precipitationProbability: number;
  }> | null;
};

export type DisplayableActivityWithFitFragment = {
  __typename?: "Activity";
  id: string;
  name?: string | null;
  activityType: ActivityType;
  startedAt?: string | null;
  totalTimeSeconds?: number | null;
  activeTimeSeconds?: number | null;
  trainingScore?: number | null;
  weightedPower?: number | null;
  intensity?: number | null;
  kilojoules?: number | null;
  totalDistanceMeters?: number | null;
  totalAscentMeters?: number | null;
  avgPower?: number | null;
  avgSpeed?: number | null;
  source?: Source | null;
  createdAt: string;
  bbox?: Array<number> | null;
  polyline?: string | null;
  plannedAt?: string | null;
  plannedTimeSeconds?: number | null;
  plannedTrainingScore?: number | null;
  fit?: {
    __typename?: "FitFile";
    timestamps: Array<number>;
    distance?: Array<number> | null;
    coords?: Array<Array<number> | null> | null;
    power?: Array<number> | null;
    cadence?: Array<number> | null;
    speed?: Array<number> | null;
    heartRate?: Array<number> | null;
    temp?: Array<number> | null;
    altitude?: Array<number> | null;
    leftRightBalance?: Array<number> | null;
    cellUUID?: Array<string> | null;
    effectiveWindSpeed?: Array<number> | null;
    cda?: Array<number> | null;
    weatherCells?: Array<{
      __typename?: "WeatherPoint";
      uuid: string;
      cell: string;
      time: string;
      resolution: WeatherResolution;
      temp: number;
      feelsLikeTemp: number;
      pressure: number;
      humidity: number;
      windSpeed: number;
      windDeg: number;
      windGust: number;
      cloudCoveragePercent: number;
      precipitationMm: number;
      precipitationProbability: number;
    }> | null;
    laps: Array<{ __typename?: "Lap"; start: string; end: string }>;
  } | null;
  course?: {
    __typename?: "Course";
    id: string;
    name: string;
    totalDistanceMeters?: number | null;
    totalAscentMeters?: number | null;
    polyline: string;
    description?: string | null;
    bbox: Array<number>;
    coords?: Array<Array<number> | null> | null;
    altitude?: Array<number> | null;
    distance?: Array<number> | null;
    weatherCells?: Array<{
      __typename?: "WeatherPoint";
      uuid: string;
      cell: string;
      time: string;
      resolution: WeatherResolution;
      temp: number;
      feelsLikeTemp: number;
      pressure: number;
      humidity: number;
      windSpeed: number;
      windDeg: number;
      windGust: number;
      cloudCoveragePercent: number;
      precipitationMm: number;
      precipitationProbability: number;
    }> | null;
  } | null;
};

export type AdminListUsersQueryVariables = Exact<{ [key: string]: never }>;

export type AdminListUsersQuery = {
  __typename?: "Query";
  admin: {
    __typename?: "AdminQuery";
    listUsers: Array<{
      __typename?: "User";
      id: string;
      name: string;
      email?: string | null;
      hasWahoo: boolean;
      hasWhoop: boolean;
      hasGarmin: boolean;
      hasStrava: boolean;
      thresholdEstimationEnabled: boolean;
      subscriptionEndsAt: string;
      trial: boolean;
      avatarURL?: string | null;
    }>;
  };
};

export type ImpersonateMutationVariables = Exact<{
  id?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type ImpersonateMutation = {
  __typename?: "Mutation";
  admin: {
    __typename?: "AdminMutation";
    impersonate: {
      __typename?: "AuthToken";
      accessToken: string;
      refreshToken: string;
    };
  };
};

export type ReprocessActivitiesMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type ReprocessActivitiesMutation = {
  __typename?: "Mutation";
  admin: { __typename?: "AdminMutation"; reprocessActivities: boolean };
};

export type ExchangeTokenMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type ExchangeTokenMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    exchangeToken: {
      __typename?: "AuthToken";
      accessToken: string;
      refreshToken: string;
    };
  };
};

export type GetActivityCurveRangeQueryVariables = Exact<{
  start: Scalars["Time"]["input"];
  end: Scalars["Time"]["input"];
}>;

export type GetActivityCurveRangeQuery = {
  __typename?: "Query";
  curves: {
    __typename?: "CurvesQuery";
    range: {
      __typename?: "Curve";
      power: Array<number>;
      powerOffsets: Array<number>;
      heartRate: Array<number>;
      heartRateOffsets: Array<number>;
      windows: Array<number>;
      activities: Array<{
        __typename?: "Activity";
        id: string;
        activityType: ActivityType;
        activeTimeSeconds?: number | null;
        totalDistanceMeters?: number | null;
        startedAt?: string | null;
      } | null>;
    };
  };
};

export type DisplayableCurveActivityFragment = {
  __typename?: "Activity";
  id: string;
  activityType: ActivityType;
  activeTimeSeconds?: number | null;
  totalDistanceMeters?: number | null;
  startedAt?: string | null;
};

export type GetGarminAuthUrlMutationVariables = Exact<{ [key: string]: never }>;

export type GetGarminAuthUrlMutation = {
  __typename?: "Mutation";
  garmin: { __typename?: "GarminMutation"; getGarminAuthUrl: string };
};

export type ExchangeGarminTokenMutationVariables = Exact<{
  verifier: Scalars["String"]["input"];
}>;

export type ExchangeGarminTokenMutation = {
  __typename?: "Mutation";
  garmin: { __typename?: "GarminMutation"; exchangeGarminToken: boolean };
};

export type RequestLoginCodeMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type RequestLoginCodeMutation = {
  __typename?: "Mutation";
  users: { __typename?: "UsersMutation"; requestLoginCode: boolean };
};

export type ListFormQueryVariables = Exact<{
  start: Scalars["Time"]["input"];
  end: Scalars["Time"]["input"];
}>;

export type ListFormQuery = {
  __typename?: "Query";
  metrics: {
    __typename?: "MetricsQuery";
    listHRVStatus: Array<{
      __typename?: "HRVStatusDate";
      date: string;
      percentile?: number | null;
      status?: HrvRecoveryStatus | null;
      metric?: {
        __typename?: "Metric";
        id: string;
        type: MetricType;
        source: Source;
        value: number;
        timestamp: string;
      } | null;
    }>;
  };
};

export type DisplayableHrvStatusFragment = {
  __typename?: "HRVStatusDate";
  date: string;
  percentile?: number | null;
  status?: HrvRecoveryStatus | null;
  metric?: {
    __typename?: "Metric";
    id: string;
    type: MetricType;
    source: Source;
    value: number;
    timestamp: string;
  } | null;
};

export type ListMetricsQueryVariables = Exact<{
  start: Scalars["Time"]["input"];
  end: Scalars["Time"]["input"];
  type?: InputMaybe<MetricType>;
}>;

export type ListMetricsQuery = {
  __typename?: "Query";
  metrics: {
    __typename?: "MetricsQuery";
    list: Array<{
      __typename?: "Metric";
      id: string;
      type: MetricType;
      source: Source;
      value: number;
      timestamp: string;
      activity?: {
        __typename?: "Activity";
        id: string;
        name?: string | null;
        activityType: ActivityType;
        startedAt?: string | null;
        totalTimeSeconds?: number | null;
        activeTimeSeconds?: number | null;
        trainingScore?: number | null;
        weightedPower?: number | null;
        intensity?: number | null;
        kilojoules?: number | null;
        totalDistanceMeters?: number | null;
        totalAscentMeters?: number | null;
        avgPower?: number | null;
        avgSpeed?: number | null;
        source?: Source | null;
        createdAt: string;
        bbox?: Array<number> | null;
        polyline?: string | null;
        plannedAt?: string | null;
        plannedTimeSeconds?: number | null;
        plannedTrainingScore?: number | null;
        course?: {
          __typename?: "Course";
          id: string;
          name: string;
          totalDistanceMeters?: number | null;
          totalAscentMeters?: number | null;
          polyline: string;
        } | null;
      } | null;
    }>;
  };
};

export type DisplayableMetricFragment = {
  __typename?: "Metric";
  id: string;
  type: MetricType;
  source: Source;
  value: number;
  timestamp: string;
  activity?: {
    __typename?: "Activity";
    id: string;
    name?: string | null;
    activityType: ActivityType;
    startedAt?: string | null;
    totalTimeSeconds?: number | null;
    activeTimeSeconds?: number | null;
    trainingScore?: number | null;
    weightedPower?: number | null;
    intensity?: number | null;
    kilojoules?: number | null;
    totalDistanceMeters?: number | null;
    totalAscentMeters?: number | null;
    avgPower?: number | null;
    avgSpeed?: number | null;
    source?: Source | null;
    createdAt: string;
    bbox?: Array<number> | null;
    polyline?: string | null;
    plannedAt?: string | null;
    plannedTimeSeconds?: number | null;
    plannedTrainingScore?: number | null;
    course?: {
      __typename?: "Course";
      id: string;
      name: string;
      totalDistanceMeters?: number | null;
      totalAscentMeters?: number | null;
      polyline: string;
    } | null;
  } | null;
};

export type CreateMetricMutationVariables = Exact<{
  input: CreateMetricInput;
}>;

export type CreateMetricMutation = {
  __typename?: "Mutation";
  metrics: {
    __typename?: "MetricsMutation";
    create: { __typename?: "Metric"; id: string };
  };
};

export type DeleteMetricMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteMetricMutation = {
  __typename?: "Mutation";
  metrics: { __typename?: "MetricsMutation"; delete: boolean };
};

export type SignupMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}>;

export type SignupMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    signup: { __typename?: "User"; id: string; name: string };
  };
};

export type ConfirmCodeMutationVariables = Exact<{
  code: Scalars["String"]["input"];
}>;

export type ConfirmCodeMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    login: {
      __typename?: "UserWithToken";
      token: {
        __typename?: "AuthToken";
        accessToken: string;
        refreshToken: string;
      };
      user: { __typename?: "User"; id: string };
    };
  };
};

export type EstimateThresholdMutationVariables = Exact<{
  [key: string]: never;
}>;

export type EstimateThresholdMutation = {
  __typename?: "Mutation";
  metrics: {
    __typename?: "MetricsMutation";
    estimateThresholdHistory: boolean;
  };
};

export type GetStravaAuthUrlMutationVariables = Exact<{ [key: string]: never }>;

export type GetStravaAuthUrlMutation = {
  __typename?: "Mutation";
  strava: { __typename?: "StravaMutation"; getStravaAuthUrl: string };
};

export type ExchangeStravaTokenMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type ExchangeStravaTokenMutation = {
  __typename?: "Mutation";
  strava: { __typename?: "StravaMutation"; exchangeStravaToken: boolean };
};

export type DisplayableStravaRouteFragment = {
  __typename?: "StravaRoute";
  id: string;
  name: string;
  private: boolean;
  distance: number;
  elevationGain: number;
  polyline: string;
  createdAt: string;
};

export type ListStravaRoutesQueryVariables = Exact<{
  page: Scalars["Int"]["input"];
}>;

export type ListStravaRoutesQuery = {
  __typename?: "Query";
  strava: {
    __typename?: "StravaQuery";
    listRoutes: Array<{
      __typename?: "StravaRoute";
      id: string;
      name: string;
      private: boolean;
      distance: number;
      elevationGain: number;
      polyline: string;
      createdAt: string;
    }>;
  };
};

export type ListTeamsQueryVariables = Exact<{
  role: TeamRole;
}>;

export type ListTeamsQuery = {
  __typename?: "Query";
  teams: {
    __typename?: "TeamsQuery";
    list: Array<{
      __typename?: "Team";
      id: string;
      name: string;
      slug: string;
      inviteCode: string;
      members: Array<{
        __typename?: "TeamUser";
        role: TeamRole;
        user: {
          __typename?: "User";
          id: string;
          name: string;
          email?: string | null;
          hasWahoo: boolean;
          hasWhoop: boolean;
          hasGarmin: boolean;
          hasStrava: boolean;
          thresholdEstimationEnabled: boolean;
          subscriptionEndsAt: string;
          trial: boolean;
          avatarURL?: string | null;
        };
      }>;
    }>;
  };
};

export type GetTeamQueryVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type GetTeamQuery = {
  __typename?: "Query";
  teams: {
    __typename?: "TeamsQuery";
    get: {
      __typename?: "Team";
      id: string;
      name: string;
      slug: string;
      inviteCode: string;
      members: Array<{
        __typename?: "TeamUser";
        role: TeamRole;
        user: {
          __typename?: "User";
          id: string;
          name: string;
          email?: string | null;
          hasWahoo: boolean;
          hasWhoop: boolean;
          hasGarmin: boolean;
          hasStrava: boolean;
          thresholdEstimationEnabled: boolean;
          subscriptionEndsAt: string;
          trial: boolean;
          avatarURL?: string | null;
        };
      }>;
    };
  };
};

export type TeamDashboardQueryVariables = Exact<{
  teamID: Scalars["String"]["input"];
  start: Scalars["Time"]["input"];
  end: Scalars["Time"]["input"];
}>;

export type TeamDashboardQuery = {
  __typename?: "Query";
  teams: {
    __typename?: "TeamsQuery";
    dashboard: {
      __typename?: "TeamDashboard";
      team: {
        __typename?: "Team";
        id: string;
        name: string;
        slug: string;
        inviteCode: string;
        members: Array<{
          __typename?: "TeamUser";
          role: TeamRole;
          user: {
            __typename?: "User";
            id: string;
            name: string;
            email?: string | null;
            hasWahoo: boolean;
            hasWhoop: boolean;
            hasGarmin: boolean;
            hasStrava: boolean;
            thresholdEstimationEnabled: boolean;
            subscriptionEndsAt: string;
            trial: boolean;
            avatarURL?: string | null;
          };
        }>;
      };
      members: Array<{
        __typename?: "DashboardUser";
        user: {
          __typename?: "User";
          id: string;
          name: string;
          email?: string | null;
          hasWahoo: boolean;
          hasWhoop: boolean;
          hasGarmin: boolean;
          hasStrava: boolean;
          thresholdEstimationEnabled: boolean;
          subscriptionEndsAt: string;
          trial: boolean;
          avatarURL?: string | null;
        };
        activities: {
          __typename?: "ActivitiesList";
          dates: Array<{
            __typename?: "ActivitiesDate";
            date: string;
            load: number;
            acuteLoad: number;
            fatigue: number;
            activities: Array<{
              __typename?: "Activity";
              id: string;
              name?: string | null;
              activityType: ActivityType;
              startedAt?: string | null;
              totalTimeSeconds?: number | null;
              activeTimeSeconds?: number | null;
              trainingScore?: number | null;
              weightedPower?: number | null;
              intensity?: number | null;
              kilojoules?: number | null;
              totalDistanceMeters?: number | null;
              totalAscentMeters?: number | null;
              avgPower?: number | null;
              avgSpeed?: number | null;
              source?: Source | null;
              createdAt: string;
              bbox?: Array<number> | null;
              polyline?: string | null;
              plannedAt?: string | null;
              plannedTimeSeconds?: number | null;
              plannedTrainingScore?: number | null;
              course?: {
                __typename?: "Course";
                id: string;
                name: string;
                totalDistanceMeters?: number | null;
                totalAscentMeters?: number | null;
                polyline: string;
              } | null;
            }>;
          }>;
        };
        latestMetrics: Array<{
          __typename?: "Metric";
          id: string;
          type: MetricType;
          source: Source;
          value: number;
          timestamp: string;
          activity?: {
            __typename?: "Activity";
            id: string;
            name?: string | null;
            activityType: ActivityType;
            startedAt?: string | null;
            totalTimeSeconds?: number | null;
            activeTimeSeconds?: number | null;
            trainingScore?: number | null;
            weightedPower?: number | null;
            intensity?: number | null;
            kilojoules?: number | null;
            totalDistanceMeters?: number | null;
            totalAscentMeters?: number | null;
            avgPower?: number | null;
            avgSpeed?: number | null;
            source?: Source | null;
            createdAt: string;
            bbox?: Array<number> | null;
            polyline?: string | null;
            plannedAt?: string | null;
            plannedTimeSeconds?: number | null;
            plannedTrainingScore?: number | null;
            course?: {
              __typename?: "Course";
              id: string;
              name: string;
              totalDistanceMeters?: number | null;
              totalAscentMeters?: number | null;
              polyline: string;
            } | null;
          } | null;
        } | null>;
      }>;
    };
  };
};

export type DisplayableDashboardTeamMemberFragment = {
  __typename?: "DashboardUser";
  user: {
    __typename?: "User";
    id: string;
    name: string;
    email?: string | null;
    hasWahoo: boolean;
    hasWhoop: boolean;
    hasGarmin: boolean;
    hasStrava: boolean;
    thresholdEstimationEnabled: boolean;
    subscriptionEndsAt: string;
    trial: boolean;
    avatarURL?: string | null;
  };
  activities: {
    __typename?: "ActivitiesList";
    dates: Array<{
      __typename?: "ActivitiesDate";
      date: string;
      load: number;
      acuteLoad: number;
      fatigue: number;
      activities: Array<{
        __typename?: "Activity";
        id: string;
        name?: string | null;
        activityType: ActivityType;
        startedAt?: string | null;
        totalTimeSeconds?: number | null;
        activeTimeSeconds?: number | null;
        trainingScore?: number | null;
        weightedPower?: number | null;
        intensity?: number | null;
        kilojoules?: number | null;
        totalDistanceMeters?: number | null;
        totalAscentMeters?: number | null;
        avgPower?: number | null;
        avgSpeed?: number | null;
        source?: Source | null;
        createdAt: string;
        bbox?: Array<number> | null;
        polyline?: string | null;
        plannedAt?: string | null;
        plannedTimeSeconds?: number | null;
        plannedTrainingScore?: number | null;
        course?: {
          __typename?: "Course";
          id: string;
          name: string;
          totalDistanceMeters?: number | null;
          totalAscentMeters?: number | null;
          polyline: string;
        } | null;
      }>;
    }>;
  };
  latestMetrics: Array<{
    __typename?: "Metric";
    id: string;
    type: MetricType;
    source: Source;
    value: number;
    timestamp: string;
    activity?: {
      __typename?: "Activity";
      id: string;
      name?: string | null;
      activityType: ActivityType;
      startedAt?: string | null;
      totalTimeSeconds?: number | null;
      activeTimeSeconds?: number | null;
      trainingScore?: number | null;
      weightedPower?: number | null;
      intensity?: number | null;
      kilojoules?: number | null;
      totalDistanceMeters?: number | null;
      totalAscentMeters?: number | null;
      avgPower?: number | null;
      avgSpeed?: number | null;
      source?: Source | null;
      createdAt: string;
      bbox?: Array<number> | null;
      polyline?: string | null;
      plannedAt?: string | null;
      plannedTimeSeconds?: number | null;
      plannedTrainingScore?: number | null;
      course?: {
        __typename?: "Course";
        id: string;
        name: string;
        totalDistanceMeters?: number | null;
        totalAscentMeters?: number | null;
        polyline: string;
      } | null;
    } | null;
  } | null>;
};

export type DisplayableTeamFragment = {
  __typename?: "Team";
  id: string;
  name: string;
  slug: string;
  inviteCode: string;
  members: Array<{
    __typename?: "TeamUser";
    role: TeamRole;
    user: {
      __typename?: "User";
      id: string;
      name: string;
      email?: string | null;
      hasWahoo: boolean;
      hasWhoop: boolean;
      hasGarmin: boolean;
      hasStrava: boolean;
      thresholdEstimationEnabled: boolean;
      subscriptionEndsAt: string;
      trial: boolean;
      avatarURL?: string | null;
    };
  }>;
};

export type CreateTeamMutationVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type CreateTeamMutation = {
  __typename?: "Mutation";
  teams: {
    __typename?: "TeamsMutation";
    create: {
      __typename?: "Team";
      id: string;
      name: string;
      slug: string;
      inviteCode: string;
      members: Array<{
        __typename?: "TeamUser";
        role: TeamRole;
        user: {
          __typename?: "User";
          id: string;
          name: string;
          email?: string | null;
          hasWahoo: boolean;
          hasWhoop: boolean;
          hasGarmin: boolean;
          hasStrava: boolean;
          thresholdEstimationEnabled: boolean;
          subscriptionEndsAt: string;
          trial: boolean;
          avatarURL?: string | null;
        };
      }>;
    };
  };
};

export type AcceptInviteMutationVariables = Exact<{
  inviteCode: Scalars["String"]["input"];
}>;

export type AcceptInviteMutation = {
  __typename?: "Mutation";
  teams: {
    __typename?: "TeamsMutation";
    acceptInvite: {
      __typename?: "Team";
      id: string;
      name: string;
      slug: string;
      inviteCode: string;
      members: Array<{
        __typename?: "TeamUser";
        role: TeamRole;
        user: {
          __typename?: "User";
          id: string;
          name: string;
          email?: string | null;
          hasWahoo: boolean;
          hasWhoop: boolean;
          hasGarmin: boolean;
          hasStrava: boolean;
          thresholdEstimationEnabled: boolean;
          subscriptionEndsAt: string;
          trial: boolean;
          avatarURL?: string | null;
        };
      }>;
    };
  };
};

export type UpdateRoleMutationVariables = Exact<{
  userID: Scalars["String"]["input"];
  teamID: Scalars["String"]["input"];
  role: TeamRole;
}>;

export type UpdateRoleMutation = {
  __typename?: "Mutation";
  teams: { __typename?: "TeamsMutation"; updateRole: boolean };
};

export type SwitchUserMutationVariables = Exact<{
  userID: Scalars["String"]["input"];
  teamID: Scalars["String"]["input"];
}>;

export type SwitchUserMutation = {
  __typename?: "Mutation";
  teams: {
    __typename?: "TeamsMutation";
    switchUser: {
      __typename?: "AuthToken";
      accessToken: string;
      refreshToken: string;
    };
  };
};

export type GetUploadUrlMutationVariables = Exact<{ [key: string]: never }>;

export type GetUploadUrlMutation = {
  __typename?: "Mutation";
  upload: { __typename?: "UploadMutation"; getURL: string };
};

export type GetUserQueryVariables = Exact<{ [key: string]: never }>;

export type GetUserQuery = {
  __typename?: "Query";
  users: {
    __typename?: "UsersQuery";
    get: {
      __typename?: "User";
      id: string;
      name: string;
      email?: string | null;
      hasWahoo: boolean;
      hasWhoop: boolean;
      hasGarmin: boolean;
      hasStrava: boolean;
      thresholdEstimationEnabled: boolean;
      subscriptionEndsAt: string;
      trial: boolean;
      avatarURL?: string | null;
    };
  };
};

export type DisplayableUserFragment = {
  __typename?: "User";
  id: string;
  name: string;
  email?: string | null;
  hasWahoo: boolean;
  hasWhoop: boolean;
  hasGarmin: boolean;
  hasStrava: boolean;
  thresholdEstimationEnabled: boolean;
  subscriptionEndsAt: string;
  trial: boolean;
  avatarURL?: string | null;
};

export type UpdateUserMutationVariables = Exact<{
  input: UpdateUserInput;
}>;

export type UpdateUserMutation = {
  __typename?: "Mutation";
  users: {
    __typename?: "UsersMutation";
    update: {
      __typename?: "User";
      id: string;
      name: string;
      email?: string | null;
      hasWahoo: boolean;
      hasWhoop: boolean;
      hasGarmin: boolean;
      hasStrava: boolean;
      thresholdEstimationEnabled: boolean;
      subscriptionEndsAt: string;
      trial: boolean;
      avatarURL?: string | null;
    };
  };
};

export type GetBillingPortalUrlMutationVariables = Exact<{
  [key: string]: never;
}>;

export type GetBillingPortalUrlMutation = {
  __typename?: "Mutation";
  users: { __typename?: "UsersMutation"; getBillingPortalURL: string };
};

export type GetWahooAuthUrlMutationVariables = Exact<{ [key: string]: never }>;

export type GetWahooAuthUrlMutation = {
  __typename?: "Mutation";
  wahoo: { __typename?: "WahooMutation"; getWahooAuthUrl: string };
};

export type ExchangeWahooTokenMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type ExchangeWahooTokenMutation = {
  __typename?: "Mutation";
  wahoo: { __typename?: "WahooMutation"; exchangeWahooToken: boolean };
};

export type GetWhoopAuthUrlMutationVariables = Exact<{ [key: string]: never }>;

export type GetWhoopAuthUrlMutation = {
  __typename?: "Mutation";
  whoop: { __typename?: "WhoopMutation"; getWhoopAuthUrl: string };
};

export type ExchangeWhoopTokenMutationVariables = Exact<{
  token: Scalars["String"]["input"];
}>;

export type ExchangeWhoopTokenMutation = {
  __typename?: "Mutation";
  whoop: { __typename?: "WhoopMutation"; exchangeWhoopToken: boolean };
};

export const DisplayableActivityFragmentDoc = gql`
  fragment DisplayableActivity on Activity {
    id
    name
    activityType
    startedAt
    totalTimeSeconds
    activeTimeSeconds
    trainingScore
    weightedPower
    intensity
    kilojoules
    totalDistanceMeters
    totalAscentMeters
    avgPower
    avgSpeed
    source
    createdAt
    bbox
    polyline
    plannedAt
    plannedTimeSeconds
    plannedTrainingScore
    course {
      id
      name
      totalDistanceMeters
      totalAscentMeters
      polyline
    }
  }
`;
export const DisplayableWeatherCellFragmentDoc = gql`
  fragment DisplayableWeatherCell on WeatherPoint {
    uuid
    cell
    time
    resolution
    temp
    feelsLikeTemp
    pressure
    humidity
    windSpeed
    windDeg
    windGust
    cloudCoveragePercent
    precipitationMm
    precipitationProbability
  }
`;
export const DisplayableActivityFitFragmentDoc = gql`
  fragment DisplayableActivityFit on FitFile {
    timestamps
    distance
    coords
    power
    cadence
    speed
    heartRate
    temp
    altitude
    leftRightBalance
    weatherCells {
      ...DisplayableWeatherCell
    }
    cellUUID
    effectiveWindSpeed
    cda
    laps {
      start
      end
    }
  }
  ${DisplayableWeatherCellFragmentDoc}
`;
export const DisplayableActivityCourseFragmentDoc = gql`
  fragment DisplayableActivityCourse on Course {
    id
    name
    description
    totalDistanceMeters
    totalAscentMeters
    polyline
    bbox
    weatherCells {
      ...DisplayableWeatherCell
    }
    coords
    altitude
    distance
  }
  ${DisplayableWeatherCellFragmentDoc}
`;
export const DisplayableActivityWithFitFragmentDoc = gql`
  fragment DisplayableActivityWithFit on Activity {
    ...DisplayableActivity
    fit {
      ...DisplayableActivityFit
    }
    course {
      ...DisplayableActivityCourse
    }
  }
  ${DisplayableActivityFragmentDoc}
  ${DisplayableActivityFitFragmentDoc}
  ${DisplayableActivityCourseFragmentDoc}
`;
export const DisplayableCurveActivityFragmentDoc = gql`
  fragment DisplayableCurveActivity on Activity {
    id
    activityType
    activeTimeSeconds
    totalDistanceMeters
    startedAt
  }
`;
export const DisplayableHrvStatusFragmentDoc = gql`
  fragment DisplayableHRVStatus on HRVStatusDate {
    date
    percentile
    metric {
      id
      type
      source
      value
      timestamp
    }
    status
  }
`;
export const DisplayableStravaRouteFragmentDoc = gql`
  fragment DisplayableStravaRoute on StravaRoute {
    id
    name
    private
    distance
    elevationGain
    polyline
    createdAt
  }
`;
export const DisplayableUserFragmentDoc = gql`
  fragment DisplayableUser on User {
    id
    name
    email
    hasWahoo
    hasWhoop
    hasGarmin
    hasStrava
    thresholdEstimationEnabled
    subscriptionEndsAt
    trial
    avatarURL
  }
`;
export const DisplayableActivitiesDateFragmentDoc = gql`
  fragment DisplayableActivitiesDate on ActivitiesDate {
    date
    load
    acuteLoad
    fatigue
    activities {
      ...DisplayableActivity
    }
  }
  ${DisplayableActivityFragmentDoc}
`;
export const DisplayableMetricFragmentDoc = gql`
  fragment DisplayableMetric on Metric {
    id
    type
    source
    value
    timestamp
    activity {
      ...DisplayableActivity
    }
  }
  ${DisplayableActivityFragmentDoc}
`;
export const DisplayableDashboardTeamMemberFragmentDoc = gql`
  fragment DisplayableDashboardTeamMember on DashboardUser {
    user {
      ...DisplayableUser
    }
    activities {
      dates {
        ...DisplayableActivitiesDate
      }
    }
    latestMetrics {
      ...DisplayableMetric
    }
  }
  ${DisplayableUserFragmentDoc}
  ${DisplayableActivitiesDateFragmentDoc}
  ${DisplayableMetricFragmentDoc}
`;
export const DisplayableTeamFragmentDoc = gql`
  fragment DisplayableTeam on Team {
    id
    name
    slug
    inviteCode
    members {
      role
      user {
        ...DisplayableUser
      }
    }
  }
  ${DisplayableUserFragmentDoc}
`;
export const ListActivityDatesDocument = gql`
  query listActivityDates($start: Time!, $end: Time!) {
    activities {
      list(start: $start, end: $end) {
        dates {
          ...DisplayableActivitiesDate
        }
      }
    }
  }
  ${DisplayableActivitiesDateFragmentDoc}
`;
export const ListActivitiesDocument = gql`
  query listActivities($start: Time!, $end: Time!) {
    activities {
      list(start: $start, end: $end) {
        dates {
          date
          activities {
            ...DisplayableActivity
          }
        }
      }
    }
  }
  ${DisplayableActivityFragmentDoc}
`;
export const GetActivityDocument = gql`
  query getActivity($id: ID!) {
    activities {
      get(id: $id) {
        ...DisplayableActivityWithFit
      }
    }
  }
  ${DisplayableActivityWithFitFragmentDoc}
`;
export const GetActivityCurveDocument = gql`
  query getActivityCurve($id: ID!) {
    curves {
      get(activityId: $id) {
        power
        powerOffsets
        heartRate
        heartRateOffsets
        windows
      }
    }
  }
`;
export const CreateActivityDocument = gql`
  mutation createActivity($input: CreateActivityInput!) {
    activities {
      create(input: $input) {
        ...DisplayableActivity
      }
    }
  }
  ${DisplayableActivityFragmentDoc}
`;
export const UpdateActivityDocument = gql`
  mutation updateActivity($input: UpdateActivityInput!) {
    activities {
      update(input: $input) {
        ...DisplayableActivity
      }
    }
  }
  ${DisplayableActivityFragmentDoc}
`;
export const DeleteActivityDocument = gql`
  mutation deleteActivity($id: ID!) {
    activities {
      delete(id: $id)
    }
  }
`;
export const AdminListUsersDocument = gql`
  query adminListUsers {
    admin {
      listUsers {
        ...DisplayableUser
      }
    }
  }
  ${DisplayableUserFragmentDoc}
`;
export const ImpersonateDocument = gql`
  mutation impersonate($id: String) {
    admin {
      impersonate(id: $id) {
        accessToken
        refreshToken
      }
    }
  }
`;
export const ReprocessActivitiesDocument = gql`
  mutation reprocessActivities($id: String!) {
    admin {
      reprocessActivities(id: $id)
    }
  }
`;
export const ExchangeTokenDocument = gql`
  mutation exchangeToken($token: String!) {
    users {
      exchangeToken(token: $token) {
        accessToken
        refreshToken
      }
    }
  }
`;
export const GetActivityCurveRangeDocument = gql`
  query getActivityCurveRange($start: Time!, $end: Time!) {
    curves {
      range(start: $start, end: $end) {
        power
        powerOffsets
        heartRate
        heartRateOffsets
        windows
        activities {
          ...DisplayableCurveActivity
        }
      }
    }
  }
  ${DisplayableCurveActivityFragmentDoc}
`;
export const GetGarminAuthUrlDocument = gql`
  mutation getGarminAuthUrl {
    garmin {
      getGarminAuthUrl
    }
  }
`;
export const ExchangeGarminTokenDocument = gql`
  mutation exchangeGarminToken($verifier: String!) {
    garmin {
      exchangeGarminToken(verifier: $verifier)
    }
  }
`;
export const RequestLoginCodeDocument = gql`
  mutation requestLoginCode($email: String!) {
    users {
      requestLoginCode(email: $email)
    }
  }
`;
export const ListFormDocument = gql`
  query listForm($start: Time!, $end: Time!) {
    metrics {
      listHRVStatus(start: $start, end: $end) {
        ...DisplayableHRVStatus
      }
    }
  }
  ${DisplayableHrvStatusFragmentDoc}
`;
export const ListMetricsDocument = gql`
  query listMetrics($start: Time!, $end: Time!, $type: MetricType) {
    metrics {
      list(start: $start, end: $end, type: $type) {
        ...DisplayableMetric
      }
    }
  }
  ${DisplayableMetricFragmentDoc}
`;
export const CreateMetricDocument = gql`
  mutation createMetric($input: CreateMetricInput!) {
    metrics {
      create(input: $input) {
        id
      }
    }
  }
`;
export const DeleteMetricDocument = gql`
  mutation deleteMetric($id: String!) {
    metrics {
      delete(id: $id)
    }
  }
`;
export const SignupDocument = gql`
  mutation signup($email: String!, $name: String!) {
    users {
      signup(input: { email: $email, name: $name }) {
        id
        name
      }
    }
  }
`;
export const ConfirmCodeDocument = gql`
  mutation confirmCode($code: String!) {
    users {
      login(code: $code) {
        token {
          accessToken
          refreshToken
        }
        user {
          id
        }
      }
    }
  }
`;
export const EstimateThresholdDocument = gql`
  mutation estimateThreshold {
    metrics {
      estimateThresholdHistory
    }
  }
`;
export const GetStravaAuthUrlDocument = gql`
  mutation getStravaAuthUrl {
    strava {
      getStravaAuthUrl
    }
  }
`;
export const ExchangeStravaTokenDocument = gql`
  mutation exchangeStravaToken($token: String!) {
    strava {
      exchangeStravaToken(token: $token)
    }
  }
`;
export const ListStravaRoutesDocument = gql`
  query listStravaRoutes($page: Int!) {
    strava {
      listRoutes(page: $page) {
        ...DisplayableStravaRoute
      }
    }
  }
  ${DisplayableStravaRouteFragmentDoc}
`;
export const ListTeamsDocument = gql`
  query listTeams($role: TeamRole!) {
    teams {
      list(role: $role) {
        ...DisplayableTeam
      }
    }
  }
  ${DisplayableTeamFragmentDoc}
`;
export const GetTeamDocument = gql`
  query getTeam($id: String!) {
    teams {
      get(id: $id) {
        ...DisplayableTeam
      }
    }
  }
  ${DisplayableTeamFragmentDoc}
`;
export const TeamDashboardDocument = gql`
  query teamDashboard($teamID: String!, $start: Time!, $end: Time!) {
    teams {
      dashboard(teamID: $teamID, start: $start, end: $end) {
        team {
          ...DisplayableTeam
        }
        members {
          ...DisplayableDashboardTeamMember
        }
      }
    }
  }
  ${DisplayableTeamFragmentDoc}
  ${DisplayableDashboardTeamMemberFragmentDoc}
`;
export const CreateTeamDocument = gql`
  mutation createTeam($name: String!) {
    teams {
      create(name: $name) {
        ...DisplayableTeam
      }
    }
  }
  ${DisplayableTeamFragmentDoc}
`;
export const AcceptInviteDocument = gql`
  mutation acceptInvite($inviteCode: String!) {
    teams {
      acceptInvite(inviteCode: $inviteCode) {
        ...DisplayableTeam
      }
    }
  }
  ${DisplayableTeamFragmentDoc}
`;
export const UpdateRoleDocument = gql`
  mutation updateRole($userID: String!, $teamID: String!, $role: TeamRole!) {
    teams {
      updateRole(userID: $userID, teamID: $teamID, role: $role)
    }
  }
`;
export const SwitchUserDocument = gql`
  mutation switchUser($userID: String!, $teamID: String!) {
    teams {
      switchUser(userID: $userID, teamID: $teamID) {
        accessToken
        refreshToken
      }
    }
  }
`;
export const GetUploadUrlDocument = gql`
  mutation getUploadURL {
    upload {
      getURL
    }
  }
`;
export const GetUserDocument = gql`
  query getUser {
    users {
      get {
        ...DisplayableUser
      }
    }
  }
  ${DisplayableUserFragmentDoc}
`;
export const UpdateUserDocument = gql`
  mutation updateUser($input: UpdateUserInput!) {
    users {
      update(input: $input) {
        ...DisplayableUser
      }
    }
  }
  ${DisplayableUserFragmentDoc}
`;
export const GetBillingPortalUrlDocument = gql`
  mutation getBillingPortalURL {
    users {
      getBillingPortalURL
    }
  }
`;
export const GetWahooAuthUrlDocument = gql`
  mutation getWahooAuthUrl {
    wahoo {
      getWahooAuthUrl
    }
  }
`;
export const ExchangeWahooTokenDocument = gql`
  mutation exchangeWahooToken($token: String!) {
    wahoo {
      exchangeWahooToken(token: $token)
    }
  }
`;
export const GetWhoopAuthUrlDocument = gql`
  mutation getWhoopAuthUrl {
    whoop {
      getWhoopAuthUrl
    }
  }
`;
export const ExchangeWhoopTokenDocument = gql`
  mutation exchangeWhoopToken($token: String!) {
    whoop {
      exchangeWhoopToken(token: $token)
    }
  }
`;

export type SdkFunctionWrapper = <T>(
  action: (requestHeaders?: Record<string, string>) => Promise<T>,
  operationName: string,
  operationType?: string,
  variables?: any,
) => Promise<T>;

const defaultWrapper: SdkFunctionWrapper = (
  action,
  _operationName,
  _operationType,
  _variables,
) => action();

export function getSdk(
  client: GraphQLClient,
  withWrapper: SdkFunctionWrapper = defaultWrapper,
) {
  return {
    listActivityDates(
      variables: ListActivityDatesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListActivityDatesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListActivityDatesQuery>(
            ListActivityDatesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "listActivityDates",
        "query",
        variables,
      );
    },
    listActivities(
      variables: ListActivitiesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListActivitiesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListActivitiesQuery>(
            ListActivitiesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "listActivities",
        "query",
        variables,
      );
    },
    getActivity(
      variables: GetActivityQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetActivityQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetActivityQuery>(GetActivityDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getActivity",
        "query",
        variables,
      );
    },
    getActivityCurve(
      variables: GetActivityCurveQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetActivityCurveQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetActivityCurveQuery>(
            GetActivityCurveDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getActivityCurve",
        "query",
        variables,
      );
    },
    createActivity(
      variables: CreateActivityMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateActivityMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateActivityMutation>(
            CreateActivityDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "createActivity",
        "mutation",
        variables,
      );
    },
    updateActivity(
      variables: UpdateActivityMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateActivityMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateActivityMutation>(
            UpdateActivityDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "updateActivity",
        "mutation",
        variables,
      );
    },
    deleteActivity(
      variables: DeleteActivityMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteActivityMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteActivityMutation>(
            DeleteActivityDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "deleteActivity",
        "mutation",
        variables,
      );
    },
    adminListUsers(
      variables?: AdminListUsersQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AdminListUsersQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AdminListUsersQuery>(
            AdminListUsersDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "adminListUsers",
        "query",
        variables,
      );
    },
    impersonate(
      variables?: ImpersonateMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ImpersonateMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ImpersonateMutation>(ImpersonateDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "impersonate",
        "mutation",
        variables,
      );
    },
    reprocessActivities(
      variables: ReprocessActivitiesMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ReprocessActivitiesMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ReprocessActivitiesMutation>(
            ReprocessActivitiesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "reprocessActivities",
        "mutation",
        variables,
      );
    },
    exchangeToken(
      variables: ExchangeTokenMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExchangeTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExchangeTokenMutation>(
            ExchangeTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "exchangeToken",
        "mutation",
        variables,
      );
    },
    getActivityCurveRange(
      variables: GetActivityCurveRangeQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetActivityCurveRangeQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetActivityCurveRangeQuery>(
            GetActivityCurveRangeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getActivityCurveRange",
        "query",
        variables,
      );
    },
    getGarminAuthUrl(
      variables?: GetGarminAuthUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetGarminAuthUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetGarminAuthUrlMutation>(
            GetGarminAuthUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getGarminAuthUrl",
        "mutation",
        variables,
      );
    },
    exchangeGarminToken(
      variables: ExchangeGarminTokenMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExchangeGarminTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExchangeGarminTokenMutation>(
            ExchangeGarminTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "exchangeGarminToken",
        "mutation",
        variables,
      );
    },
    requestLoginCode(
      variables: RequestLoginCodeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<RequestLoginCodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<RequestLoginCodeMutation>(
            RequestLoginCodeDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "requestLoginCode",
        "mutation",
        variables,
      );
    },
    listForm(
      variables: ListFormQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListFormQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListFormQuery>(ListFormDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "listForm",
        "query",
        variables,
      );
    },
    listMetrics(
      variables: ListMetricsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListMetricsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListMetricsQuery>(ListMetricsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "listMetrics",
        "query",
        variables,
      );
    },
    createMetric(
      variables: CreateMetricMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateMetricMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateMetricMutation>(
            CreateMetricDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "createMetric",
        "mutation",
        variables,
      );
    },
    deleteMetric(
      variables: DeleteMetricMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<DeleteMetricMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<DeleteMetricMutation>(
            DeleteMetricDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "deleteMetric",
        "mutation",
        variables,
      );
    },
    signup(
      variables: SignupMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SignupMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SignupMutation>(SignupDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "signup",
        "mutation",
        variables,
      );
    },
    confirmCode(
      variables: ConfirmCodeMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ConfirmCodeMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ConfirmCodeMutation>(ConfirmCodeDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "confirmCode",
        "mutation",
        variables,
      );
    },
    estimateThreshold(
      variables?: EstimateThresholdMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<EstimateThresholdMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<EstimateThresholdMutation>(
            EstimateThresholdDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "estimateThreshold",
        "mutation",
        variables,
      );
    },
    getStravaAuthUrl(
      variables?: GetStravaAuthUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetStravaAuthUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetStravaAuthUrlMutation>(
            GetStravaAuthUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getStravaAuthUrl",
        "mutation",
        variables,
      );
    },
    exchangeStravaToken(
      variables: ExchangeStravaTokenMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExchangeStravaTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExchangeStravaTokenMutation>(
            ExchangeStravaTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "exchangeStravaToken",
        "mutation",
        variables,
      );
    },
    listStravaRoutes(
      variables: ListStravaRoutesQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListStravaRoutesQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListStravaRoutesQuery>(
            ListStravaRoutesDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "listStravaRoutes",
        "query",
        variables,
      );
    },
    listTeams(
      variables: ListTeamsQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ListTeamsQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ListTeamsQuery>(ListTeamsDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "listTeams",
        "query",
        variables,
      );
    },
    getTeam(
      variables: GetTeamQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetTeamQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetTeamQuery>(GetTeamDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getTeam",
        "query",
        variables,
      );
    },
    teamDashboard(
      variables: TeamDashboardQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<TeamDashboardQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<TeamDashboardQuery>(TeamDashboardDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "teamDashboard",
        "query",
        variables,
      );
    },
    createTeam(
      variables: CreateTeamMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<CreateTeamMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<CreateTeamMutation>(CreateTeamDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "createTeam",
        "mutation",
        variables,
      );
    },
    acceptInvite(
      variables: AcceptInviteMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<AcceptInviteMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<AcceptInviteMutation>(
            AcceptInviteDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "acceptInvite",
        "mutation",
        variables,
      );
    },
    updateRole(
      variables: UpdateRoleMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateRoleMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateRoleMutation>(UpdateRoleDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateRole",
        "mutation",
        variables,
      );
    },
    switchUser(
      variables: SwitchUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<SwitchUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<SwitchUserMutation>(SwitchUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "switchUser",
        "mutation",
        variables,
      );
    },
    getUploadURL(
      variables?: GetUploadUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetUploadUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUploadUrlMutation>(
            GetUploadUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getUploadURL",
        "mutation",
        variables,
      );
    },
    getUser(
      variables?: GetUserQueryVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetUserQuery> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetUserQuery>(GetUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "getUser",
        "query",
        variables,
      );
    },
    updateUser(
      variables: UpdateUserMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<UpdateUserMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<UpdateUserMutation>(UpdateUserDocument, variables, {
            ...requestHeaders,
            ...wrappedRequestHeaders,
          }),
        "updateUser",
        "mutation",
        variables,
      );
    },
    getBillingPortalURL(
      variables?: GetBillingPortalUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetBillingPortalUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetBillingPortalUrlMutation>(
            GetBillingPortalUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getBillingPortalURL",
        "mutation",
        variables,
      );
    },
    getWahooAuthUrl(
      variables?: GetWahooAuthUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetWahooAuthUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetWahooAuthUrlMutation>(
            GetWahooAuthUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getWahooAuthUrl",
        "mutation",
        variables,
      );
    },
    exchangeWahooToken(
      variables: ExchangeWahooTokenMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExchangeWahooTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExchangeWahooTokenMutation>(
            ExchangeWahooTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "exchangeWahooToken",
        "mutation",
        variables,
      );
    },
    getWhoopAuthUrl(
      variables?: GetWhoopAuthUrlMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<GetWhoopAuthUrlMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<GetWhoopAuthUrlMutation>(
            GetWhoopAuthUrlDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "getWhoopAuthUrl",
        "mutation",
        variables,
      );
    },
    exchangeWhoopToken(
      variables: ExchangeWhoopTokenMutationVariables,
      requestHeaders?: GraphQLClientRequestHeaders,
    ): Promise<ExchangeWhoopTokenMutation> {
      return withWrapper(
        (wrappedRequestHeaders) =>
          client.request<ExchangeWhoopTokenMutation>(
            ExchangeWhoopTokenDocument,
            variables,
            { ...requestHeaders, ...wrappedRequestHeaders },
          ),
        "exchangeWhoopToken",
        "mutation",
        variables,
      );
    },
  };
}
export type Sdk = ReturnType<typeof getSdk>;

